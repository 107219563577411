import * as React from "react"
import {Header} from "../components/header";
import {Footer} from "../components/footer";

const TermsAndConditions = () => {
    return (
        <main>
            <Header/>
            <div className="page">
                <div className="container">
                    <h2><strong>Terms and Conditions</strong></h2>
                    <br/><hr/><br/>
                    <p>These Terms of Use (the “Terms”) are a legally binding agreement between Influere Corp and its corporate affiliates and subsidiaries as may be from time to time (“Influere”, “we” or “us”) and the visitors of our website, available at: www.influereinvestigations.com and any and all websites and applications that Influere operates or that link to these Terms, including but not limited to, any and all pages within each such website or application, any equivalent mirror, replacement, substitute or backup website or application, and pages that are associated with each such website or application, and shall govern the access, download and use of our website and any services, products or mobile applications which may be provided therein (collectively, the “Website and the “Services”, respectively).</p>
                    <p>By accessing or using the Website, you acknowledge and agree that you have read, understood and agreed to be bound by these Terms and to comply with all applicable laws and regulations. If you do not agree with these Terms, then you must stop accessing or using the Website. The Terms shall govern any and all kind of uses and features offered via the Website as may be available from to time to time.</p>
                    <p>By accessing the Website, you agree that any claim, dispute or controversy of whatever nature arising out of or relating to these Terms and/or your use of the Website and the Services shall be resolved by final and binding arbitration in accordance with the process described below, to the maximum extent permitted under applicable law. Please read these Terms carefully as they contain a binding arbitration procedure and a waiver of your right to participate in class actions against Influere. </p>
                    <p>In order to learn how we collect and process information, please review our privacy policy, available at: <a href="https://influereinvestigations.com/privacy-policy">influereinvestigations.com/privacy-policy</a> (“Privacy Policy”). The Privacy Policy is hereby incorporated by reference into these Terms as an integral part thereof. By accepting these Terms, you expressly indicate that you have read and understood the Privacy Policy. </p>
                    <p>We reserve the right, at our discretion, to revise, modify or update these Terms at any time. Such changes shall be effective upon publication of the amended terms. The last revision will be reflected in the “Last Revised” date above. Please make sure to review these Terms periodically. In the event of a material change to these Terms we will make best commercial efforts to inform you.</p>
                    <h2>1. THE SERVICES</h2>
                    <p>1.1. We, at Influere have developed the Website and have made it available for use by our users in accordance with this Terms and applicable laws and regulations. </p>
                    <p>1.2. You hereby acknowledge and agree that for the purposes of improvement, repair, or upgrade of the Website or for any of the reasons for termination as set forth hereunder we shall be entitled, without any liability, to restrict, refuse, suspend, limit or interrupt the Website or any part thereof, without any notice to you. </p>
                    <p>1.3. Please note that any use or access of the Website by anyone under the age 18 is strictly prohibited. By accepting the Terms, you hereby represent that you are at least 18 years or older and that you have the legal capacity to enter into the Terms.</p>
                    <h2>INTELLECTUAL PROPERTY RIGHTS </h2>
                    <p>2.1. You hereby acknowledge and agree that all intellectual property rights including, but not limited to, all copyrights, trademarks, patents, characters, trade names, software code, trade secrets, icons, logos, layouts, and graphics are Influere’s exclusive intellectual property and are all protected by national and international intellectual property laws and treaties including all applicable copyright laws and regulations. Please note that the Website may contain third-party copyrights, graphics, logos or trademarks, and you are not granted any right or license with respect to our trademarks or the trademarks of any third party.</p>
                    <p>2.2. Subject to the terms and conditions of these Terms and your compliance with applicable laws and regulation, Influere hereby grants you a limited, non-exclusive, non-transferable, non-sublicensable, worldwide license and the right to use and access the Website on a device that you own or control, solely for your own internal purposes. Nothing in these Terms shall be construed to grant you any rights in the Website, and all such rights not granted hereunder are expressly reserved by Influere. </p>
                    <p>2.3. As part of the Website and the Services, we look forward to Users’ comments, suggestions or feedback including with respect to improvements, compliments, or other issues as related to the Website and the Services whether by directly messaging or corresponding with us or by posting content to our social accounts (collectively, the “Feedback”). Please be aware that Feedback provided to us shall not be considered confidential or proprietary. You acknowledge and agree that by submitting Feedback to us, you hereby grant us a fully paid-up, worldwide, non-exclusive, perpetual, sub-licensable, irrevocable license to use, reproduce, display and publish such Feedback or use any such Feedback for the improvement of the Website and the Services, without any additional consideration.</p>
                    <p>2.4. Except as set forth hereunder, you may not copy, alter, adapt, modify, reproduce, distribute or commercially exploit any materials, including graphics, video, text, audio, software code, design, logos or user interface from the Website, without our prior written consent. You hereby represent and undertake that you will not make any copies of, decompile or disassemble, reverse engineer, distribute, modify, adapt, translate or otherwise transfer, rent, lease, resell, sublicense or otherwise commercially exploit the Website or any part thereof, except as permitted hereunder. </p>
                    <h2>3. USER REPRESENTATIONS AND UNDERTAKINGS</h2>
                    <p>3.1. You acknowledge and agree that any download, installation, use or access you make in connection with the Website is done at your sole risk, and shall be your sole responsibility, in accordance with these Terms. </p>
                    <h2>4. WARRANTY DISCLAIMERS</h2>
                    <p>4.1. You acknowledge and agree that your use of the Website is at your own discretion and sole risk, and that the entire risk as to the results and performance of the Website, including, among others, any damages to your mobile device, computer system, well-being or any other device used to access the Website, or data stored on such devices, is solely yours.</p>
                    <p>4.2. TO THE MAXIMUM EXTENT NOT PROHIBITED UNDER APPLICABLE LAW, THE WEBSITE IS PROVIDED ON AN “AS IS”, “AS AVAILABLE” AND “WITH ALL FAULTS” BASIS. USE OF THE WEBSITE IS AT YOUR OWN RISK. THE WEBSITE IS PROVIDED WITHOUT WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO, IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. WITHOUT LIMITING THE FOREGOING, INFLUERE AND ITS LICENSORS DO NOT WARRANT THAT THE WEBSITE OR ANY CONTENT THEREIN IS ACCURATE, RELIABLE OR CORRECT; THAT THE WEBSITE WILL MEET YOUR REQUIREMENTS; THAT THE WEBSITE WILL BE AVAILABLE AT ANY PARTICULAR TIME OR LOCATION, UNINTERRUPTED OR SECURE; THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE WEBSITE ISFREE OF DEFECTS, ERRORS, VIRUSES OR OTHER HARMFUL COMPONENTS. ANY CONTENT DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE USE OF THE WEBSITE IS DOWNLOADED AT YOUR OWN RISK AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM SUCH DOWNLOAD.</p>
                    <p>4.3 You acknowledge and agree that we are not liable for any failure to perform our obligations hereunder in case such failure results from any cause beyond our reasonable control, including, without limitation, mechanical, electronic or communications failure or degradation, and we shall not be held responsible for any damages caused to you or any third party that may result from third party technical issues such as traffic congestion, slow connections, or overload of our or other servers or any issues of telecommunications or internet providers.</p>
                    <h2>5. LIMITATION OF LIABILITY</h2>
                    <p>5.1. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT SHALL INFLUERE, ITS AFFILIATES, DIRECTORS, EMPLOYEES OR ITS LICENSORS BE LIABLE FOR ANY DIRECT, INDIRECT, PUNITIVE, INCIDENTAL, SPECIAL, CONSEQUENTIAL OR EXEMPLARY DAMAGES, INCLUDING WITHOUT LIMITATION DAMAGES FOR LOSS OF PROFITS, GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES, THAT RESULT FROM THE USE OF, OR INABILITY TO USE, THE WEBSITE. UNDER NO CIRCUMSTANCES WILL WE BE RESPONSIBLE FOR ANY DAMAGE, LOSS OR INJURY RESULTING FROM HACKING, TAMPERING OR OTHER UNAUTHORIZED ACCESS OR USE OF THE SERVICES OR THE INFORMATION CONTAINED THEREIN.</p>
                    <p>5.2. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, INFLUERE ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY (I) ERRORS, MISTAKES, OR INACCURACIES OF CONTENT; (II) PERSONAL INJURY OR PROPERTY DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM YOUR ACCESS TO AND USE OF OUR WEBSITE; (III) ANY UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS AND/OR ANY AND ALL PERSONAL INFORMATION STORED THEREIN; (IV) ANY INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM THE WEBSITE; (V) ANY BUGS, VIRUSES, TROJAN HORSES, OR THE LIKE THAT MAY BE TRANSMITTED TO OR THROUGH THE WEBSITE BY ANY THIRD PARTY; (VI) THE DEFAMATORY, OFFENSIVE, OR ILLEGAL CONDUCT OF ANY USER OR THIRD PARTY OR ANY INFRINGEMENT OF THIRD PARTY RIGHTS BY USER IN CONNECTION WITH THE WEBSITE. </p>
                    <p>5.3. WITHOUT DEROGATING FROM THE FOREGOING, IN NO EVENT SHALL INFLUERE, ITS AFFILIATES, DIRECTORS, EMPLOYEES, OR LICENSORS BE LIABLE TO YOU FOR ANY CLAIMS, PROCEEDINGS, LIABILITIES, OBLIGATIONS, DAMAGES, LOSSES OR COSTS IN AN AMOUNT EXCEEDING THE LOWER OF: (A) THE AMOUNT YOU PAID TO INFLUERE HEREUNDER IN THE 6 MONTHS PRECEDING SUCH LIABILITY OR (B) US$ 500. THIS LIMITATION OF LIABILITY SECTION APPLIES WHETHER THE ALLEGED LIABILITY IS BASED ON CONTRACT, TORT, NEGLIGENCE, STRICT LIABILITY, OR ANY OTHER BASIS, EVEN IF INFLUERE HAS BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE. THE FOREGOING LIMITATION OF LIABILITY SHALL APPLY TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW IN THE APPLICABLE JURISDICTION.</p>
                    <p>5.4. Please note that certain jurisdictions do not permit the exclusion of certain warranties or limitation of liability for incidental or consequential damages, which means that some of the above limitations may not apply to you. In these jurisdictions, the foregoing exclusions and limitations will be enforced to the greatest extent permitted by applicable law. </p>
                    <h2>6. PRIVACY</h2>
                    <p>6.1. We care about your privacy and wish to protect your privacy. In accordance, you may be asked to submit or enable the transmission of certain Personal Data, which may be required for the operability of our Website or the Website. To the extent you choose to use or access certain features of the Website please review the terms and condition of our Privacy Policy available here: <a href="https://influereinvestigations.com/privacy-policy">influereinvestigations.com/privacy-policy</a>.</p>
                    <p>6.2. Please be aware that our Website or the Website are intended for general audiences, which are not directed to persons under 18 years old. If a parent or guardian becomes aware that his/her child has provided us with Personal Data without their consent, he/she should contact us immediately. We do not knowingly collect or solicit Personal Data from people under 18 years old. If we become aware that a person under 18 years old has provided us with Personal Data, we will delete such data from our databases.</p>
                    <h2>7. INDEMNITY</h2>
                    <p>You agree to defend, indemnify and hold harmless Influere, its directors, employees and agents, from and against any and all claims, damages, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) your use of and access to the Website; (ii) any breach of the terms and conditions of these Terms by you; (iii) your infringement of any third party rights, including, among others, any copyright, intellectual property right or privacy right in connection with the Website; or (iv) any claim that an act or omission by you has caused damage to a third party in connection with the Website; or (v) your breach of any law, rule or regulation as applicable to you in connection with the Website.</p>
                    <h2>8. GOVERNING LAW </h2>
                    <p>The Terms shall be governed by the laws of the State New York, without respect to its conflict of laws principles, and notwithstanding the jurisdiction where you are based. You irrevocably submit to the exclusive jurisdiction of the competent courts in New York City, New York to settle any dispute which may arise out of, under, or in connection with these Terms. The place of performance shall be New York City, New York. We provide no warranty or guarantee that The Services or information available on it complies with laws other than those of New York.</p>
                    <h2>9. MISCELLANEOUS </h2>
                    <p>9.1. Should any one or more of the provisions of these Terms be determined to be invalid, unlawful, or unenforceable in any respect, the validity, legality, and enforceability of the remaining provisions of these Terms shall not in any way be affected or impaired by such determination and will remain in full force and effect, and the provision affected will be construed so as to be enforceable to the maximum extent permissible by law.</p>
                    <p>9.2. A delay or omission by either party to exercise any right under these Terms shall not be construed to be a waiver of such right. A waiver by either party of any of the performance provisions of these Terms or any breach with respect to such performance shall not be construed to be a waiver of any succeeding performance or breach.</p>
                    <p>9.3. Influere reserves the right to transfer, assign, sublicense or pledge its rights and obligations under these Terms, in whole or in part, in the event of a merger, sale of assets or other similar corporate transaction in which Influere may be involved in. You may not transfer, assign, sublicense or pledge any of your or your rights or obligations under these Terms without Influere’s prior written approval.</p>
                    <p>9.4. Influere will not be liable for any delay or failure to perform the Services within the Website if and to the extent that such delay or failure to perform is caused or otherwise brought about by circumstances beyond Influere’s reasonable control, including strikes, lockouts, labor troubles, pandemics, restrictive government or judicial orders or decrees, riots, insurrection, war, terrorism, Acts of God, and/or inclement weather, which Influere is unable to prevent by the exercise of reasonable due diligence.</p>
                    <p>9.5. The Terms, together with any other legal notices, exhibits, guidelines and agreements published by Influere via the Website, shall constitute the entire agreement between you and Influere concerning the Website. </p>
                    <p>9.6. You hereby waive any rights or requirements under any laws or regulations in any jurisdiction which require an original (non-electronic) signature or delivery or retention of non-electronic records, to the extent permitted under applicable mandatory law. </p>
                    <h2>10. CONTACT US</h2>
                    <p>If you have any questions regarding these Terms, you may contact us at:  <a href="mailto:info@influereinvestigations.com">info@influereinvestigations.com</a></p>
                </div>
            </div>
            <Footer/>
        </main>
    )
}

export default TermsAndConditions

export const Head = () => <title>Influere Investigations | Terms & Conditions</title>